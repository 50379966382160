import './App.css';
import {
  useState,
  useEffect,
  useMemo,
  useRef,
  useReducer
} from 'react';

import {
  v4 as uuidv4
} from 'uuid';

import mqtt from 'mqtt';
import axios from 'axios';

import QuillComponent from './components/quill';
import FileDropzone from './components/dropzone';
import WindowEvents from './components/windowevents';
import AddedDocumentComponent from './components/messenger/document';
import MessageLineComponent from './components/messenger/messageline';

const activeReducer = (prevState, action) => {

  switch (action.type) {
    case 'add':
      const isDuplicate = prevState.some(item => item.id === action.payload.id);
      if (isDuplicate) {
        return prevState;
      }
      return [...prevState, action.payload];
    case 'remove':
      return prevState.filter(item => item.id !== action.payload.id);
    case 'clear':
      return prevState = [];
    default:
      return prevState;
  }
}

function App() {

  const urlLogin = 'https://ti5vv2n6zqxoaqoxwe72yx6rxq0bicmn.lambda-url.eu-west-2.on.aws/';
  const urlCheckAuth = 'https://ujcc4lznqwwyykodpvjpbcbrgu0pympf.lambda-url.eu-west-2.on.aws/';
  const urlS3PutSignedUrlRequest = 'https://qnymatk5wjetw74hrlefh7air40avboo.lambda-url.eu-west-2.on.aws/';

  const [endPoints, setEndPoints] = useState();
  const [reconnectCount, setReconnectCount] = useState(0);
  const [login, setLogin] = useState(false);
  const [connectionError, setConnectionError] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [passcode, setPasscode] = useState('');
  const [username, setUsername] = useState('');
  const [myId, setMyId] = useState('');
  const [setAuth, setSetAuth] = useState(null);
  const [checkedAuth, setCheckedAuth] = useState(null);
  const [loginError, setLoginError] = useState(null);

  const [channelList, setChannelList] = useState([]);
  const [createNewChannel, setCreateNewChannel] = useState(false);

  const [channelHistoryPositions, setChannelHistoryPositions] = useState([]);
  const [loadingChannel, setLoadingChannel] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [hasLoadMore, setHasLoadMore] = useState(false);

  const [timeDiff, setTimeDiff] = useState(null);
  const [newMessage, setNewMessage] = useState(null);
  const [processPacket, setProcessPacket] = useState(null);
  const [messages, setMessages] = useState({});
  const [currentMessages, setCurrentMessages] = useState(null);
  const [fileUploading, setFileUploading] = useState(false);
  const [attachedDocuments, setAttachedDocuments] = useState({ document: [] });
  const [messageParts, setMessageParts] = useState({});
  const [lastViewedChannelAt, setLastViewedChannelAt] = useState(null);
  const [newFrom, setNewFrom] = useState(null);

  const [iamActive, setIamActive] = useState(false);
  const [lastActiveTime, setLastActiveTime] = useState(0);
  const [lastActiveTimerId, setLastActiveTimerId] = useState(null);

  const [deleteFile, setDeleteFile] = useState(null);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [deletingMessage, setDeletingMessage] = useState(null);
  const [editMessage, setEditMessage] = useState(null);
  const [editMessageDocs, setEditMessageDocs] = useState([]);

  const [userSettings, setUserSettings] = useState({});
  const [audioEnabled, setAudioEnabled] = useState(true);
  const [getFileList, setGetFileList] = useState(false);

  const [mqttClient, setMQTTClient] = useState();
  const [mqttPublish, setMQTTPublish] = useState(null);
  const [topicClient, setTopicClient] = useState('');
  const [getHistory, setGetHistory] = useState(null);

  const [panelOpen, setPanelOpen] = useState(null);
  const [noScroll, setNoScroll] = useState(false);
  const [noScrollAction, setNoScrollAction] = useState(false);

  const [rvAppData, setRvAppData] = useState({});

  const [activeUsers, dispatchUsers] = useReducer(activeReducer, []);
  const [friendsList, dispatchFriends] = useReducer(activeReducer, []);
  const [usersOnline, dispatchOnline] = useReducer(activeReducer, []);

  const fileList = useRef([]);
  const currentMessageElements = useRef([]);
  const channelName = useRef([]);
  const channelPanel = useRef();
  const usersOnlinePanel = useRef();
  const friendsPanel = useRef();
  const userListRefs = useRef([]);
  const friendsName = useRef([]);
  const dropzoneElement = useRef();
  const newChannelName = useRef();
  const loginButton = useRef();
  const messageInput = useRef();
  const appMessages = useRef();
  const usernameInput = useRef();
  const quillRef = useRef();
  const editIndex = useRef();
  const switchingChannel = useRef();
  const lastScrollPosition = useRef(0);
  const activeTopics = useRef([]);
  const activeUsersMessage = useRef();


  useEffect(() => {
    setRvAppData(window.rvAppData);
    console.log('rvAppData', window.rvAppData);
  },
    []);

  useEffect(() => {
    if (lastViewedChannelAt === null)
      return;

    const message = lastViewedChannelAt;
    const topic = message.topic !== undefined ? message.topic : topicClient.id;

    var currentChannel, currentChannelIndex;
    var updatedChannelList = channelList.filter((channel, index) => {
      if (channel.id === topic) {
        currentChannel = channel;
        currentChannelIndex = index;
      }
      return channel.id !== topic;
    });
    currentChannel['last_viewed'] = message.timestamp;
    updatedChannelList.splice(currentChannelIndex, 0, currentChannel)
    setChannelList(updatedChannelList);
    const newSettings = { ...userSettings, topics: updatedChannelList }
    setUserSettings(newSettings);
    setMQTTPublish([
      {
        topic: 'user/' + myId,
        message: { username: myId, ...userSettings }
      }
    ]);
    setLastViewedChannelAt(null);

  }, 
    [lastViewedChannelAt]);


  useEffect(() => {
    if (isLoggedIn) {
      var timeNow = Date.now().toString();
      var timerMark = parseFloat(timeNow.slice(timeNow.length - 4, timeNow.length));

      if (iamActive > 0 && timerMark - lastActiveTime > 100) {
        if (lastActiveTime === 0) {
          setMQTTPublish([
            {
              topic: topicClient.id + "/act",
              message: {
                id: myId.toString(),
                alias: username, message: { activity: 'active' }
              }
            }
          ]);
        }
        setLastActiveTime(parseFloat(timerMark));
        clearTimeout(lastActiveTimerId);
        setLastActiveTimerId(
          setTimeout(
            () => {
              setMQTTPublish([
                {
                  topic: topicClient.id + "/act",
                  message: {
                    id: myId.toString(),
                    alias: username,
                    message: { activity: 'inactive' }
                  }
                }
              ]);
              setLastActiveTime(0);
              setIamActive(0);
            }
            , 400)
        );
        setLastActiveTime(timerMark);
      }
    }
  }, 
    [iamActive]);


  useEffect(() => {
    if (mqttPublish === null || mqttClient === undefined)
      return;

    mqttPublish.forEach(item => {
      mqttClient.publish(item.topic, JSON.stringify(item.message));
    });
    setMQTTPublish(null);
  },
    [mqttPublish, mqttClient]);


  useEffect(() => {
    if (processPacket === null)
      return;
    const msg = JSON.parse(processPacket.message.toString());
    const topic = processPacket.topic;
    const message = msg.message;
    const id = myId;

    if (topic === 'last-will' || topic === 'client-disconnected') {
      dispatchOnline({ type: 'remove', payload: { id: msg.id, alias: msg.alias } });
    } else if (topic === 'client-connected' && msg.id !== id.toString()) {
      setMQTTPublish([
        {
          topic: 'direct/' + msg.id,
          message: { id: id.toString(), alias: username }
        }
      ]);
      dispatchOnline({ type: 'add', payload: { id: msg.id, alias: msg.alias } });
    } else if (topic === 'direct/' + id.toString()) {
      if ('message' in msg) {
        setNewMessage(msg);
      } else {
        dispatchOnline({ type: 'add', payload: { id: msg.id, alias: msg.alias } });
      }
    } else if (activeTopics.current.includes(topic)) {
      if ('message' in msg) {
        if ('activity' in msg.message) {
          if (msg.id !== myId && topic === topicClient.id + "/act") {
            dispatchUsers({ type: msg.message.activity === 'active' ? 'add' : 'remove', payload: { id: msg.id, alias: msg.alias } });
          }
        } else {
          if (msg.message.totalParts > 1) {
            var existingMessageParts = [];
            messageParts[msg.timestamp] = messageParts[msg.timestamp] === undefined ? [] : messageParts[msg.timestamp];
            existingMessageParts = messageParts[msg.timestamp];
            existingMessageParts.filter(part => part.part === msg.message.part);
            existingMessageParts.push(msg.message);

            if (existingMessageParts.length === msg.message.totalParts) {
              var totalMessage = '';

              existingMessageParts.sort((a, b) => {
                return a.part - b.part;
              });

              existingMessageParts.forEach(part => {
                totalMessage += part.text;
              });
              msg.message.text = totalMessage;
              delete messageParts[message.timestamp];
            } else {
              messageParts[msg.timestamp] = existingMessageParts;
              setMessageParts({ ...messageParts });
              return;
            }
          }
          setNewMessage(msg);
        }
      } else {
        if (topicClient.id === topic) {
          currentMessageElements.current.forEach((element, index) => {
            if (element === null) {
              return;
            }
            if (parseFloat(element.getAttribute('data-timestamp')) === parseFloat(msg.timestamp)) {
              currentMessageElements.current[index].classList.add('deleted');
            }
          });
        } else {
          var currentMessagesVar = messages[topic.replace(/\//g, "")],
            newMessages = [];
          if (currentMessagesVar !== undefined) {
            newMessages = currentMessagesVar.filter(message => message.timestamp !== msg.timestamp);
          }
          var updatedMessages = messages;
          updatedMessages[topic.replace(/\//g, "")] = newMessages;
          setMessages({ ...updatedMessages });
        }
      }
    }
    setProcessPacket(null);

  }, 
    [processPacket, messageParts, messages, myId, topicClient.id, username]);


  useEffect(() => {
    function setMQTTsubscribe() {
      if (mqttClient === undefined) {
        return;
      }
      var defaultTopics = [
        { 'id': 'client-connected', 'name': 'client-connected' },
        { 'id': 'client-disconnected', 'name': 'client-disconnected' },
        { 'id': 'direct/' + myId.toString(), 'name': 'direct-messages' },
        { 'id': 'last-will', 'name': 'last-will' }
      ];
      var myTopics = userSettings.topics;
      var mainTopics = myTopics.concat(defaultTopics);
      var actionTopics = myTopics.map(topicVal => { return topicVal.id + "/act" });
      var allTopics = [];

      mainTopics.forEach(topicVal => {
        allTopics.push(topicVal.id);
      });

      actionTopics.forEach(topicVal => {
        allTopics.push(topicVal);
      });

      activeTopics.current = allTopics;
      allTopics.forEach(allTopic => {
        if (allTopic !== undefined)
          mqttClient.subscribe(allTopic);
      });
    }
    userSettings.topics && setMQTTsubscribe();
  },
    [mqttClient]);


  useEffect(() => {
    if (deletingMessage === null || topicClient.id === undefined)
      return;

    var newMessages = currentMessages.filter(message => message.timestamp !== deletingMessage);
    var updatedMessages = messages;
    updatedMessages[topicClient.id.replace(/\//g, "")] = newMessages;
    setMessages({ ...updatedMessages });
    setCurrentMessages(newMessages);
    setNoScroll(false);
    setNoScrollAction(false);
    setDeletingMessage(null);
  },
    [deletingMessage, topicClient.id, currentMessages, messages]);


  useEffect(() => {
    if (topicClient.id === undefined)
      return;

    var topicClientId = topicClient.id.replace(/\//g, "");
    const currentMessages = messages[topicClientId] === undefined ?
      []
      : messages[topicClientId];
    setCurrentMessages([...currentMessages]);
  }, 
    [messages, topicClient.id]);


  useEffect(() => {
    if (topicClient.id === undefined)
      return;

    if (messages[topicClient.id.replace(/\//g, "")] === undefined) {
      var fromNew = null;
      userSettings.topics.forEach(item => {
        if (item.id === topicClient.id) {
          fromNew = item.last_viewed;
        }
      })

      setNewFrom(fromNew);
    } else {
      setNewFrom(-1);
    }

    var newSettings = userSettings;
    newSettings.state.activeChannel = topicClient;
    setMQTTPublish([
      {
        topic: 'user/' + username,
        message: { 'username': myId, 'userdata': newSettings }
      }
    ]);

    setLoadingChannel(true);
    setGetHistory(topicClient.id);
  },
    [topicClient]);


  useMemo(async () => {
    if (!isLoggedIn || username === undefined || endPoints === undefined) {
      return;
    }

    if (getFileList === false)
      return;

    if (fileList.length > 0)
      return;

    return await Promise.all([
      fetch(endPoints.files, {
        method: 'GET',
        mode: 'cors',
        headers: {
          "Accept": "application/json",
          "id": myId
        }
      })
        .then(res => res.json())
        .then(filelist => {
          return filelist.files;
        })]).then((files) => {
          fileList.current = files[0];
          setGetFileList(false);
        }).catch((err) => {
          console.log(err);
        })
  }, 
    [getFileList]);


  useEffect(() => {
    if (channelPanel.current !== undefined) {
      channelPanel.current.style.maxHeight = channelPanel.current.scrollHeight + "px";
    }
  },
    [createNewChannel]);

  useEffect(() => {

    if (currentMessages === null)
      return;

    currentMessageElements.current = currentMessageElements.current.filter(element => element !== null);

    if (switchingChannel.current === true) {
      appMessages.current.scrollTop = appMessages.current.scrollHeight - lastScrollPosition.current;
      switchingChannel.current = false;
      lastScrollPosition.current = 0;
      setNoScrollAction(false);
      return;
    } else if (currentMessageElements.current.length > 0 && !noScroll) {
      lastScrollPosition.current = appMessages.current.scrollHeight - appMessages.current.clientHeight;
      currentMessageElements.current[currentMessageElements.current.length - 1].scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    } else if (currentMessages.length > 0) {
      if (currentMessages[currentMessages.length - 1].username === username && !noScrollAction) {
        lastScrollPosition.current = appMessages.current.scrollHeight - appMessages.current.clientHeight;
        currentMessageElements.current[currentMessageElements.current.length - 1].scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
      }
    }

    setNoScrollAction(false);
  },
    [currentMessages]);


  useEffect(() => {
    function loginAction() {
      if (loginButton.current.className === false) {
        return;
      } else if (loginButton.current.className === 'App-Login LoggingIn') {
        return;
      }
      loginButton.current.className = 'App-Login LoggingIn';
      usernameInput.current.disabled = true;
      switchingChannel.current = true;
    }
    login && loginAction();
  }, 
    [login]);


  useEffect(() => {
    function reconnectChecks() {
      if (reconnectCount > 5) {
        mqttClient.end();
        setConnectionError(true);
        setMQTTClient(null);
        setIsLoggedIn(false);
        setLoginError('Connection error');
      }
    }
    reconnectChecks();
  },
    [reconnectCount, mqttClient, isLoggedIn]);


  useEffect(() => {
    function activeUserMessage() {
      if (activeUsersMessage.current === undefined || activeUsersMessage.current === null)
        return;

      if (activeUsers.length === 0) {
        activeUsersMessage.current.innerText = '';
        return;
      } else if (activeUsers.length > 2) {
        activeUsersMessage.current.innerText = 'Multiple users are typing';
        return;
      }
      var newActiveMessage = "";
      var userAlias = '';
      activeUsers.forEach(
        user => {
          usersOnline.forEach(usera => {
            if (usera.id === user.id) {
              userAlias = usera.alias;
              return;
            }
          })
          newActiveMessage += userAlias + " is typing";
        }
      );
      activeUsersMessage.current.innerText = newActiveMessage;
    }
    activeUsers && activeUserMessage();
  },
    [activeUsers, usersOnline]);


  useEffect(() => {
    function deleteUserMessage() {
      if (deleteMessage !== null) {
        setMQTTPublish([
          {
            topic: topicClient.id, message: {
              'id': myId,
              'timestamp': deleteMessage,
              'topic': topicClient.id,
              'msgtype': 'message'
            }
          }
        ]);
        setNoScroll(true);
        setDeleteMessage(null);
        setNoScrollAction(true);
        setEditMessage(null);
      }
    }
    deleteMessage && deleteUserMessage()
  },
    [deleteMessage, topicClient.id, mqttClient, myId]);


  useEffect(() => {
    function editUserMessage() {
      if (editMessage === null) {
        if (editIndex.current !== null) {
          currentMessageElements.current[editIndex.current].classList.remove('editing');
        }
        editIndex.current = null;
        setEditMessageDocs([]);
        return;
      }
      if (editMessage.message.document !== undefined) {
        setEditMessageDocs(editMessage.message.document);
        setAttachedDocuments({ document: editMessage.message.document });
      }

      messageInput.current.value = editMessage.message.text;
      quillRef.current.editor.root.innerHTML = editMessage.message.text;
    }
    editMessage && editUserMessage()
  },
    [editMessage]);


  useEffect(() => {
    const checkAuth = () => {
      if (checkedAuth === true) {
        return;
      }
      return fetch(urlCheckAuth, {
        method: 'GET',
        mode: 'cors',
        headers: {
          "Accept": "application/json",
        }
      })
        .then(res => res.json())
        .then(response => {
          if (response.message === 'Access denied') {
            setSetAuth(true);
            return true;
          }
          setSetAuth(false);
          return true;
        });
    }
    checkAuth();
    checkedAuth && setCheckedAuth(true);
  },
    [checkedAuth]);


  useEffect(() => {
    async function getChannelHistory() {
      if (getHistory === null || topicClient.id === undefined) {
        return;
      }

      const thisTopic = topicClient.id.replace(/\//g, "");

      if (loadingChannel) {
        if (messages[thisTopic] !== undefined) {
          setCurrentMessages(messages[thisTopic]);
          setHasLoadMore(channelHistoryPositions[thisTopic] !== null);
          setLoadingChannel(false);
          setNoScroll(true);
          setNoScrollAction(true);
          return;
        }
      }

      var headers = {
        "Accept": "application/json",
        "ChannelId": topicClient.id
      };

      if (loadingMore) {
        let lastKey =
        {
          'channelId': topicClient.id,
          'timestamp': channelHistoryPositions[thisTopic]
        };
        headers['LastKey'] = JSON.stringify(lastKey);
      }

      return await fetch(endPoints.history, {
        method: 'GET',
        mode: 'cors',
        headers: headers
      })
        .then(res => res.json())
        .then(newMessagev => {
          var visibleMessages = [];
          var hasLoadMoreMessages = false;

          if (newMessagev.length > 0) {

            var last_viewed = 0;
            userSettings.topics.forEach(item => {
              if (item.id === topicClient.id) {
                last_viewed = item.last_viewed !== undefined ? item.last_viewed : 0;
              }
            })

            if (last_viewed < newMessagev[0].timestamp) {
              setLastViewedChannelAt(JSON.parse(newMessagev[0].message));
            }

            newMessagev.forEach(
              message => {
                var messageContents = JSON.parse(message.message);
                if ('username' in messageContents) {
                  visibleMessages.unshift(messageContents);
                } else if ('more' in messageContents) {
                  channelHistoryPositions[thisTopic] = messageContents.more.timestamp;
                  setChannelHistoryPositions({ ...channelHistoryPositions });
                  hasLoadMoreMessages = true;
                }
              }
            );
          }

          if (!hasLoadMoreMessages) {
            channelHistoryPositions[thisTopic] = null;
            setChannelHistoryPositions({ ...channelHistoryPositions });
          }

          if (messages[thisTopic] !== undefined) {
            visibleMessages = visibleMessages.concat(messages[thisTopic]);
          }

          messages[thisTopic] = visibleMessages;

          setMessages({ ...messages });

          if (loadingMore === true) {
            appMessages.current.scrollTop = lastScrollPosition.current;
            switchingChannel.current = true;
          }

          setHasLoadMore(hasLoadMoreMessages);
          setLoadingMore(false);
          setLoadingChannel(false);

        });
    }
    getChannelHistory();
    getHistory && setGetHistory(null);
  },
    [getHistory]);


  useEffect(() => {
    if (newMessage === null)
      return;

    function addMessage(message) {

      var newMessages = messages,
        messageTopic = message.topic.replace(/\//g, "");

      if (newMessages[messageTopic] === undefined) {
        newMessages[messageTopic] = [];
      }

      var existingMessage = newMessages[messageTopic].filter(msg => msg.timestamp === message.timestamp);
      if (existingMessage.length > 0) {
        newMessages[messageTopic] = newMessages[messageTopic].map(msg => {
          if (msg.timestamp === message.timestamp) {
            return message;
          }
          return msg;
        });
        setMessages({ ...messages, ...newMessages });
        return;
      }

      newMessages[messageTopic].push(message);
      setMessages({ ...messages, ...newMessages });

      if (message.topic === topicClient.id) {
        setLastViewedChannelAt(message);
      }

      if (message.username !== myId && audioEnabled && message.topic === topicClient.id) {
        const audio = new Audio('chime.mp3');
        audio.play();
      }

      if (message.username !== myId && message.topic !== topicClient.id) {
        var channelIndex;
        var channel = channelList.filter((channel, index) => {
          if (channel.id === message.topic) {
            channelIndex = index;
            return channel.id === message.topic;
          } else {
            return false;
          }
        });

        var newChannelList = channelList.filter(channel => channel.id !== message.topic);

        if (channel.length > 0) {
          channel[0].unread = true;
          newChannelList.splice(channelIndex, 0, channel[0]);
          setChannelList(newChannelList);
        }
      }
    }

    addMessage(newMessage);
    setNewMessage(null);
  },
    [newMessage]);


  useEffect(() => {
    if (!loadingChannel && isLoggedIn) {
      appMessages.current.scrollTop = appMessages.current.scrollHeight;
    }
  },
    [loadingChannel, topicClient, isLoggedIn]);


  useEffect(() => {
    if (usersOnline.length === 0 || panelOpen !== 0)
      return;
    accordionOnClick(0, true);
  }, 
    [usersOnline, panelOpen]);


  useEffect(() => {
    if (usersOnlinePanel.current !== undefined && panelOpen !== null) {
      accordionOnClick(panelOpen[0]);
    }
  }, 
    [panelOpen])


  useEffect(() => {
    var newSettings = { ...userSettings, state: { ...userSettings.state, audioEnabled: audioEnabled } };
    setMQTTPublish([
      {
        topic: 'user/' + username,
        message: { 'username': myId, 'userdata': newSettings }
      }
    ]);
  }, 
    [audioEnabled]);


  const stickyScrollCheck = () => {
    if (appMessages.current !== undefined) {
      if (
        appMessages.current.scrollTop === appMessages.current.scrollHeight - appMessages.current.clientHeight
        ||
        username === currentMessages[currentMessages.length - 1].username
      ) {
        setNoScroll(false);
      } else {
        setNoScroll(true);
      }
    }
  }


  const accordionOnClick = (index, resize = false) => {
    var acc = document.getElementsByClassName("accordion");
    var panel = document.getElementsByClassName("panel");
    if (panel[index].style.maxHeight && !resize) {
      panel[index].style.maxHeight = null;
      acc[index].classList.remove('active');
    } else {
      if (!resize) {
        for (var i = 0; i < acc.length; i++) {
          panel[i].style.maxHeight = null;
          acc[i].classList.remove('active');
        }
      }
      panel[index].style.maxHeight = panel[index].scrollHeight + "px";
      acc[index].classList.add('active');
    }
  }


  const userChatSettings = endPoint => {
    return fetch(endPoint, {
      method: 'GET',
      mode: 'cors',
      headers: {
        "Accept": "application/json",
        "Username": username.toString(),
      }
    })
      .then(res => res.json())
      .then(settings => {
        Promise.all([
          setUserSettings(settings),
          setMyId(settings.id),
          setTopicClient(settings.state.activeChannel),
          setAudioEnabled(settings.state.audioEnabled !== undefined ? settings.state.audioEnabled : true),
          setChannelList(settings.topics),
          setUsername(settings.username),
        ]);
        settings.friends.forEach(friend => {
          dispatchFriends({ type: 'add', payload: { id: friend.id, alias: friend.alias } });
        });
        setTimeDiff(settings.timestamp - Date.now());
        return settings;
      });
  }


  const createChannel = () => {
    if (newChannelName.current.value.length > 0) {
      const channel_uuid = uuidv4().toString();
      const channelId = 'chat/' + channel_uuid;
      const newChannel = { 'name': newChannelName.current.value, 'id': channelId };
      const newChannels = channelList;
      newChannels.push({ ...newChannel });
      setChannelList(newChannels);
      const newSettings = { ...userSettings, topics: newChannels }
      setUserSettings(newSettings);
      mqttClient.subscribe([channelId, channelId + "/act"]);
      activeTopics.current.push(channelId);
      setMQTTPublish([
        {
          topic: 'user/' + username,
          message: { 'username': myId, 'userdata': newSettings }
        }
      ]);
      setCreateNewChannel(false);
      newChannelName.current.value = '';
    }
  }


  const clearEditor = () => {
    quillRef.current.editor.root.innerHTML = "";
    quillRef.current.editor.root.setAttribute('data-placeholder', 'Enter your message here');
    setAttachedDocuments({ document: [] });
    setEditMessage(null);
  }


  const clearWYSIWYG = e => {
    e.preventDefault();
    clearEditor();
    setEditMessage(null);
    setDeleteFile(null);
    if (currentMessageElements.current[editIndex.current] !== undefined)
      currentMessageElements.current[editIndex.current].classList.remove('editing');
  }


  const MQTTConnect = (mqttUrl, id) => {
    var options = {
      will: {
        topic: 'last-will',
        payload: JSON.stringify({ id: id, alias: username }),
      },
      clientId: id,
      protocolId: 'MQTT',
      protocolVersion: 5,
      qos: 0,
      clean: true,
    };

    const client = mqtt.connect(mqttUrl, options);

    client.on(
      'error',
      (error) => {
        console.log('Connection error', error);
        setReconnectCount(reconnectCount + 1);
      }
    );

    client.on(
      'connect',
      () => {
        setMQTTPublish([
          {
            topic: 'client-connected',
            message: { 'id': id, 'alias': username }
          }
        ]);
      }
    );

    client.on(
      'message',
      (topic, message) => {
        setProcessPacket({ topic: topic, message: message });
      }
    );

    setIsLoggedIn(true);
    return client;
  }


  const handleSubmit = async e => {
    e.preventDefault();

    if (messageInput.current.value === '' || messageInput.current.value === '<p><br></p>') {
      quillRef.current.editor.root.setAttribute('data-placeholder', 'Please enter a message');
      return;
    }

    const totalMessage = messageInput.current.value;
    const parts = totalMessage.match(/.{1,100}/g);
    const totalParts = parts.length;
    var documentNode = [];
    var messageTimestamp = Date.now() + timeDiff;

    if (attachedDocuments.document.length > 0) {
      const docs = await Promise.all(
        attachedDocuments.document.map(
          async file => {
            var exists = false;
            editMessageDocs.forEach(doc => {
              if (doc.name === file.name) {
                exists = true;
                return;
              }
            });

            if (exists) {
              if (file.width !== undefined && file.height !== undefined)
                return { name: file.name, path: file.path, type: file.type, width: file.width, height: file.height };
              else
                return { name: file.name, path: file.path, type: file.type };
            }

            setFileUploading(file.name.replace(".", ""));

            const filePutSignedUrl = {
              method: 'GET',
              mode: 'cors',
              headers: {
                "Accept": "application/json",
                "Authorization": passcode,
                "File": JSON.stringify({ 'name': topicClient.id + "/" + username + "/" + file.name, 'type': 'put' }),
              }
            };

            return fetch(urlS3PutSignedUrlRequest, filePutSignedUrl)
              .then(res => res.json())
              .then(async tokens => {
                var s3puturl = tokens.url;
                axios.put(s3puturl, file.contents, {
                  headers: {
                    'Content-Type': file.type,
                    'Content-Encoding': 'binary',
                  }
                });

                if (file.type.startsWith('image/')) {
                  const img = new Image();
                  img.src = URL.createObjectURL(file.file);
                  await new Promise(resolve => {
                    img.onload = () => {
                      file.width = img.width;
                      file.height = img.height;
                      resolve();
                    };
                  });
                }

                if (document.getElementById('attachment_' + file.name.replace(".", "")) !== null)
                  document.getElementById('attachment_' + file.name.replace(".", "")).classList.add("uploaded");
                if (file.width !== undefined && file.height !== undefined)
                  return { name: file.name, path: topicClient.id + "/" + username + "/" + file.name, type: file.type, width: file.width, height: file.height };
                else
                  return { name: file.name, path: topicClient.id + "/" + username + "/" + file.name, type: file.type };
              });
          }));

      documentNode = { document: docs };
    }

    setFileUploading(false);
    setAttachedDocuments({ document: [] });
    var editing = editMessage !== null;
    if (editing) {
      messageTimestamp = editMessage.timestamp;
      currentMessageElements.current[editIndex.current].classList.remove('editing');
      setNoScroll(true);
      setDeleteMessage(null);
      setNoScrollAction(true);
    }

    var tmpTopicClient = topicClient.id;
    var messageArray = [];

    for (var i = 1; i < totalParts + 1; i++) {
      var payload = {
        username: myId.toString(),
        message: { alias: username, text: parts[i - 1], totalParts: totalParts, part: i, edited: editing },
        timestamp: messageTimestamp,
        topic: tmpTopicClient.toString(),
        msgtype: 'message'
      };
      if (documentNode !== null && i === totalParts) {
        payload.message.document = documentNode.document;
      }
      messageArray.push({ topic: tmpTopicClient, message: payload });
    }

    setMQTTPublish(messageArray);
    quillRef.current.editor.root.setAttribute('data-placeholder', 'Enter your message here');
    quillRef.current.editor.deleteText(0, totalMessage.length);
    setEditMessage(null);
    setDeleteFile(null);
  }


  const handleLogin = async e => {
    e.preventDefault();

    const requestMetadata = {
      method: 'GET',
      mode: 'cors',
      headers: {
        "Accept": "application/json",
        "Authorization": passcode,
      }
    };

    setLogin(true);

    return await fetch(urlLogin, requestMetadata)
      .then(res => res.json())
      .then(async tokens => {
        if (tokens.message === 'Access denied') {
          setLoginError('Access Denied');
          return false;
        }
        setLoginError(null);
        setEndPoints(tokens.endpoints);
        const loggedInUser = await userChatSettings(tokens.endpoints.user);
        return { url: tokens.url, userId: loggedInUser.id };
      }).then(async result => {
        if (result !== false) {
          return await new Promise(resolve => {
            setMQTTClient(MQTTConnect(result.url, result.userId));
            setGetFileList(true);
            resolve(true);
          }).then(() => {
            setConnectionError(false);
            setLogin(false);
            setLoadingChannel(true);
            return true;
          }).catch(err => {
            console.log(err);
            setLogin(false);
          })
        }
        return false;
      });
  }


  const handleLogout = async e => {
    e.preventDefault();
    try {
      setIsLoggedIn(false);
      setMQTTPublish([
        {
          topic: 'client-disconnected',
          message: { username: myId, alias: username }
        }
      ]);

      activeTopics.current.forEach(topicVal => {
        mqttClient.unsubscribe(topicVal);
      });

      mqttClient.end(true);
      await new Promise((resolve) => {
        setMQTTClient();
        resolve(true);
      }).then(async () => {
        await Promise.all([
          setMyId(''),
          setUsername(''),
          setNewMessage(null),
          setMessages({}),
          setChannelList([]),
          setTimeDiff(null),
          setEndPoints([]),
          setChannelHistoryPositions([]),
          setLoadingChannel(true),
          setCheckedAuth(null),
          setSetAuth(null),
          setAttachedDocuments({ document: [] }),
          setMessageParts({}),
          setEditMessage(null),
          setDeleteMessage(null),
          setEditMessageDocs([]),
          setHasLoadMore(false),
          setCurrentMessages(null),
          setUserSettings({}),
          setAudioEnabled(true),
          setPasscode(null),
          setTopicClient(''),
          setNoScroll(false),
          setNoScrollAction(false),
          setLoadingMore(false),
          setDeleteFile(null),
          setCreateNewChannel(false),
          setFileUploading(false),
          setLoginError(null),
          setLogin(false),
          setConnectionError(false),
          setReconnectCount(0),
          setGetFileList(false),
          setCheckedAuth(true),
          setLastActiveTime(0),
          setIamActive(0),
          dispatchUsers({ type: 'clear' }),
          dispatchFriends({ type: 'clear' }),
          dispatchOnline({ type: 'clear' }),
          () => { fileList.current = []; return true; },
          () => { currentMessageElements.current = []; return true; },
          () => { channelName.current = []; return true; },
          () => { userListRefs.current = []; return true; },
          () => { friendsName.current = []; return true; },
          () => { dropzoneElement.current = []; return true; },
          () => { newChannelName.current = []; return true; },
          () => { loginButton.current = []; return true; },
          () => { messageInput.current = []; return true; },
          () => { appMessages.current = []; return true; },
          () => { usernameInput.current = []; return true; },
          () => { quillRef.current = []; return true; },
          () => { editIndex.current = []; return true; },
          () => { switchingChannel.current = []; return true; },
          () => { lastScrollPosition.current = []; return true; },
          () => { activeTopics.current = []; return true; },
        ]);
      })
    } catch (err) {
      window.location.reload();
    }
  }


  if (isLoggedIn) {
    return (
      <div className="App">
        <WindowEvents />
        <FileDropzone
          dropzoneElement={dropzoneElement}
          setAttachedDocuments={setAttachedDocuments}
          attachedDocuments={attachedDocuments}
          setEditMessageDocs={setEditMessageDocs}
          editMessageDocs={editMessageDocs}
          deleteFile={deleteFile}
        />
        <header className="App-header">
          <h2>
            <span>{topicClient.name.replace('chat/', '')}</span><br />
            <span>{topicClient.id}</span>
          </h2>
          <div className="logged_in_as">
            <strong>{username}</strong><br />
            <span>{myId}</span> <button className="App-Logout" onClick={handleLogout}>
              <span>
                Logout
              </span>
            </button>
            {connectionError ? <div>
              <span>
                There was an error connecting to the chat server.  Please try again later.
              </span>
            </div> : null}
            <span className="audio-toggle" onClick={() => setAudioEnabled(!audioEnabled)}>{audioEnabled ? '🔊' : '🔇'}</span>
          </div>
          <div className='App-body'>
            <div className="App-references">
              <div className={'accordion'} >
                <h4 id="App-userlist" onClick={() => { setPanelOpen([0, panelOpen === 0]) }}>
                  <span>
                    Users Online
                  </span>
                </h4>
                <ul id="App-userlist-list" className={"panel"} ref={usersOnlinePanel} key={'usersonline'}>
                  {usersOnline.map((user) => {
                    return (
                      <li key={user.id + "uo"} ref={el => userListRefs.current[user.id] = el} onClick={(e) => {
                        e.preventDefault();
                        setLoadingChannel(true);
                        setTopicClient(user.id);
                      }}>{user.alias}</li>
                    );
                  })}
                  {usersOnline.length === 0 ? <li>No users online</li> : null}
                </ul>
              </div>
              <div className={'accordion'}>
                <h4 onClick={() => { setPanelOpen([1, panelOpen === 1]) }}>
                  <span>
                    Friends
                  </span>
                </h4>
                <ul id="App-friends-list" className={'panel'} ref={friendsPanel}>
                  {friendsList.map((friend) => {
                    return (
                      <li key={friend.id + "fr"} ref={el => friendsName.current[friend.id] = el} onClick={(e) => {
                        e.preventDefault();
                        setLoadingChannel(true);
                        setTopicClient(friend.id);
                      }}>{friend.alias}</li>
                    );
                  })}
                </ul>
              </div>
              <div className={'accordion'}>
                <h4 onClick={() => { setPanelOpen([2, panelOpen === 2]) }}>
                  <span>
                    Channels
                  </span>
                </h4>
                <ul id="App-channels-list" className={'panel'} ref={channelPanel}>
                  {channelList.map((channel) => {
                    if (channel.name.substr(channel.name.length - 3, 3) !== 'act')
                      return (
                        <li key={channel.id + "ci"} ref={el => channelName.current[channel.id] = el}
                          onClick={(e) => {
                            e.preventDefault();
                            var cachedMessages = false;
                            if (messages[channel.id.replace(/\//g, "")] !== undefined) {
                              cachedMessages = true;
                            }

                            if (channel.unread !== undefined && channel.unread) {
                              channel.unread = false;
                              setChannelList([...channelList]);
                            }

                            switchingChannel.current = true;
                            setLoadingChannel(!cachedMessages);
                            setTopicClient(channel);
                          }}><span className={topicClient.id === channel.id ? "active_topic" : null}>
                            {channel.name.replace('chat/', '')}
                            {channel.unread !== undefined && channel.unread ?
                              <span className={'new_message_notifications'}>
                                <span className={'new_message_notice'}></span>
                              </span>
                              : null}
                          </span>
                        </li>
                      );
                    return false;
                  })}
                  <li className="new_channel">
                    <span onClick={() => { setCreateNewChannel(!createNewChannel) }}>
                      <span className={createNewChannel ? "visible" : "hidden"} onClick={createChannel}>Create</span>
                      {createNewChannel ? " | Cancel" : "Create a new channel"}
                    </span>
                    <span className={createNewChannel ? "visible" : "folded"}>
                      <input ref={newChannelName} type="text" placeholder="Enter channel name" />
                    </span>
                  </li>
                </ul>
              </div>
              <div className={'accordion'}>
                <h4 onClick={() => { setPanelOpen([3, panelOpen === 3]) }}>
                  <span>
                    File Management
                  </span>
                </h4>
                <ul id="App-file-list" className={getFileList ? 'loading panel' : 'panel'}>
                  {fileList.current !== null ? fileList.current.map((file, index) => {
                    return (
                      <li key={file + index} onClick={(e) => {
                        e.preventDefault();
                        setAttachedDocuments({ document: [{ name: file, path: file }] });
                      }}>{file.replace('chat/', '').replace(username + '/', '')}</li>
                    );
                  }) : null}
                  {getFileList ? <li>
                    Loading...<br />
                    <img src="./loader2.gif" alt="loading" className={"loading_norm"} />
                  </li> : null}
                </ul>
              </div>
            </div>
            <div className={"App-messages-block"}>
              <div className={"App-messages"} ref={appMessages} onScroll={stickyScrollCheck}>
                {hasLoadMore && !loadingChannel ? <div className="more-messages" onClick={() => {
                  lastScrollPosition.current = appMessages.current.scrollHeight;
                  setLoadingMore(true);
                  setGetHistory(topicClient.id);
                }}>
                  {loadingMore && !loadingChannel ?
                    <img src="loader2.gif" alt="loading" className={"loading_norm"} /> :
                    <span className={"load_more"}>
                      Load more messages...
                    </span>
                  }
                </div> : null}
                {loadingChannel ? <div id="App-messages-placeholder">
                  <div>
                    <span>
                      Loading messages...
                    </span>
                    <br />
                    <img src="loader2.gif" alt="loading" className={"loading_norm"} />
                  </div>
                </div> : null}
                {!loadingChannel && currentMessages !== undefined && currentMessages.length === 0 ? <div id="App-messages-placeholder">
                  <span>
                    No messages in this channel yet.
                  </span>
                  <br />
                  <span>
                    Welcome to HROChat.  You are now connected, new messages will appear here.
                  </span>
                </div> : null}
                {!loadingChannel && currentMessages !== undefined && currentMessages.length > 0 ? currentMessages.map((message, index) => {
                  var day = new Date(message.timestamp).toLocaleDateString();
                  var previousMessage = currentMessages[currentMessages.indexOf(message) + 1];
                  var previousDay = previousMessage !== undefined ? new Date(previousMessage.timestamp).toLocaleDateString() : null;
                  return (
                    <div key={'message-' + index}>
                      <MessageLineComponent
                        ref={el => currentMessageElements.current[index] = el}
                        setAttachedDocuments={setAttachedDocuments}
                        message={message}
                        key={message.timestamp}
                        index={index}
                        editIndex={editIndex}
                        myId={myId}
                        setEditMessage={setEditMessage}
                        setDeleteMessage={setDeleteMessage}
                        setDeletingMessage={setDeletingMessage}
                        passcode={passcode}
                      />
                      {day !== previousDay && previousDay !== null ? <span className="new-day-marker">{day}</span> : null}
                      {previousMessage !== undefined && message.timestamp === newFrom ? <span id={"new_marker"}>NEW MESSAGES</span> : null}
                    </div>)
                }) : null}
              </div>
              <div className="channel_activity" ref={activeUsersMessage}></div>
              <form
                className="App-form"
                onSubmit={handleSubmit}
              >
                <textarea
                  type="text"
                  id="message"
                  ref={messageInput}
                />
                <QuillComponent
                  placeholder={"Enter your message here"}
                  quillRef={quillRef}
                  setIamActive={setIamActive}
                />
                <div className="action_buttons">
                  <button
                    className={"attach"}
                    onClick={(e) => {
                      e.preventDefault();
                      dropzoneElement.current.click(e);
                    }}
                  >
                  </button>
                  <div className={"attached_documents"}>
                    <ul className={"document_list"}>
                      {attachedDocuments.document.map((file, index) =>
                        <AddedDocumentComponent
                          file={file}
                          key={index}
                          fileUploading={fileUploading}
                          delete={() => { setDeleteFile(file); }}
                        />
                      )}
                    </ul>
                  </div>
                  <div className={"action_button_block"}>
                    <button>
                      {editMessage !== null ? 'Update' : 'Send'}
                    </button>
                    <button
                      className={"clear"}
                      onClick={clearWYSIWYG}
                    >
                      {editMessage !== null ? 'Cancel' : 'Clear'}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </header>
      </div>
    );
  } else {
    return (
      <div className={"App"}>
        <header className={"App-header"}>
          <h1>
            HROChat
          </h1>
          <h4>
            <span>
              The most basic chat client on the planet.
            </span>
          </h4>
          {loginError}
          <form
            className={"App-form App-login-form"}
            onSubmit={handleLogin}
          >
            <input
              type="text"
              ref={usernameInput}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Username"
            />
            <button ref={loginButton} className="App-Login">Login</button>
            <div className="login_notice">
              <span>
                This is a proof of concept and feasibility study into the solution
              </span>
            </div>
            {connectionError ? <div>
              <span>
                There was an error connecting to the chat server.  Please try again later.
              </span>
            </div> : null}
            {setAuth === true ? <div>
              <span>
                Enter the passcode to access the chat
              </span>
              <br />
              <input type="text" id="code" name="code" placeholder="passcode, required if not attached to the VPN." value={passcode} onChange={(e) => setPasscode(e.target.value)} />
            </div> : null}
          </form>
        </header>
      </div>
    );
  }
}

export default App;